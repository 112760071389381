import {useNavigate} from "react-router";
import {getAuthUser} from "../firebase/check";
import {Button} from "@mui/material";
import {logoutUser} from "../firebase/auth";
import {useEffect, useState} from "react";
import {getFirestore} from "firebase/firestore";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import {fetchDoctorList} from "../firebase/fetch/doctor";
import {fetchClinicList} from "../firebase/fetch/clinics";
import {FileCopyRounded, Logout} from "@mui/icons-material";


export default function Dashboard(){
    const [pendingAppointments, setPendingAppointments] = useState([])
    const [totalPendingAppointments, setTotalPendingAppointments] = useState(0)
    const [appointments, setAppointments] = useState([])
    const [totalAppointments, setTotalAppointments] = useState(0)
    const [doctors, setDoctors] = useState([])
    const [totalDoctors, setTotalDoctors] = useState(0)
    const [clinics, setClinics] = useState([])
    const [totalClinics, setTotalClinics] = useState(0)
    const [currentDoctor, setCurrentDoctor] = useState({})
    const [currentUserEmail, setCurrentUserEmail] = useState((getAuthUser())?getAuthUser().email:"n/a")

    const navigator = useNavigate();
    const database = getFirestore()

    useEffect(()=>{
        const list = []
        appointments.forEach(data=>{
            if(!Boolean(data.status))
                list.push(data)
        })
        setPendingAppointments(list)
        setTotalPendingAppointments(list.length)
        return ()=>{}
    },[appointments])

    useEffect(()=>{
        const unsubscribeAppointment = onSnapshot(collection(database, "appointment-request"), (snapshot)=>{
            const list = []
            snapshot.forEach((snap)=>{
                list.push(snap.data())
            })
            setAppointments(list)
            setTotalAppointments(list.length)
        })

        fetchDoctorList().then(res=>{
            setDoctors(res)
            setTotalDoctors(res.length)
            // getting current doc
            let temp_doc = null
            let found = false
            for (let i=0; i<res.length;i++){
                temp_doc = res[i]
                for (let j=0; j<temp_doc.emails.length;j++){
                    if(temp_doc.emails[j].text === currentUserEmail){
                        found = true
                        break;}
                }
                if(found){
                    setCurrentDoctor(temp_doc)
                    break;
                }
            }
        })

        fetchClinicList().then(res=>{
            setClinics(res)
            setTotalClinics(res.length)
        })

        return ()=>{
           unsubscribeAppointment()
        }
    },[])

    return (
        <div className={"p-8"}>
            <div className={"p-4 mb-12 flex flex-row justify-between content-center items-center"}>
                <div className={"text-4xl font-bold text-blue-500 text-left"}>Welcome {currentDoctor.name}!</div>
                <Button color={"error"}
                        startIcon={<Logout/>}
                        size={"large"}
                        onClick={()=>{logoutUser()
                            .then(()=>{
                            })
                            .catch((err)=>{
                                console.log(err.message)
                            })
                        }}
                ><span className={"text-lg"}>Logout</span></Button>
            </div>
            <div className={"grid grid-cols-1 grid-flow-row md:grid-cols-4 gap-4"}>
                <div className={"bg-red-50 p-4 rounded-xl"}>
                    <div className={"text-md text-red-500 text-left"}>Pending Appointments</div>
                    <div className={"text-4xl font-bold text-red-500 text-right"}>{totalPendingAppointments}</div>
                </div>
                <div className={"bg-yellow-50 p-4 rounded-xl"}>
                    <div className={"text-md text-yellow-500 text-left"}>Total Bookings</div>
                    <div className={"text-4xl font-bold text-yellow-500 text-right"}>{totalAppointments}</div>
                </div>
                <div className={"bg-green-50 p-4 rounded-xl"}>
                    <div className={"text-md text-green-500 text-left"}>Total Doctors</div>
                    <div className={"text-4xl font-bold text-green-500 text-right"}>{totalDoctors}</div>
                </div>
                <div className={"bg-blue-50 p-4 rounded-xl"}>
                    <div className={"text-md text-blue-500 text-left"}>Total Clinics</div>
                    <div className={"text-4xl font-bold text-blue-500 text-right"}>{totalClinics}</div>
                </div>
                <div className={"bg-blue-500 p-4 rounded-xl hover:bg-blue-400 hover:shadow-lg"}
                onClick={()=>{
                        navigator("/prescription")
                }}>
                    <div className={"text-md text-white text-center text-xl font-bold p-2"}> <FileCopyRounded/> Generate Prescription</div>
                </div>
            </div>
        </div>
    )
}
