import React, {useEffect, useRef, useState} from "react"
import Printer, { print } from 'react-pdf-print'
import {Button, TextField} from "@mui/material";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import {ComponentToPrint, Prescription} from "./Prescription";
import {useParams} from "react-router";
import {fetchDoctorList} from "../firebase/fetch/doctor";
import {getAuthUser} from "../firebase/check";

export default function GeneratePrescription(){
    const ref = useRef()

    const {bookName, bookAge, bookGender, bookContact} = useParams()

    const [doctorList, setDoctorList] = useState([])
    const [currentDoctor, setCurrentDoctor] = useState(null)
    const [currentUserEmail, setCurrentUserEmail] = useState(getAuthUser().email)

    const [name, setName] = useState("")
    const [age, setAge] = useState("")
    const [gender, setGender] = useState("")
    const [contact, setContact] = useState("")
    const [weight, setWeight] = useState("")
    const [bloodPressure, setBloodPressure] = useState("")
    const [tests, setTests] = useState("")
    const [prescription, setPrescription] = useState("")
    const [docName, setDocName] = useState("")
    const [docPhone, setDocPhone] = useState("")
    const [docDegree, setDocDegree] = useState("")
    const [docSpec, setDocSpec] = useState("")

    useEffect(()=>{
        if(bookName)
            setName(bookName)
        if(bookAge)
            setAge(bookAge)
        if(bookGender)
            setGender(bookGender)
        if(bookContact)
            setContact(bookContact)

        fetchDoctorList().then(res=>{
            // getting current doc
            let temp_doc = null
            let found = false
            for (let i=0; i<res.length;i++){
                temp_doc = res[i]
                for (let j=0; j<temp_doc.emails.length;j++){
                    if(temp_doc.emails[j].text === currentUserEmail){
                        found = true
                        break;}
                }
                if(found){
                    setCurrentDoctor(temp_doc)
                    break;
                }
            }
        }).catch(err=>{
            alert(err.message)
        })

        return ()=>{}
    },[])

    useEffect(()=>{
        if(currentDoctor){
            setDocName(currentDoctor.name)
            let docPhone = ""
            for (let i in currentDoctor.phoneNumbers){

                if(docPhone === "")
                    docPhone += currentDoctor.phoneNumbers[i].text
                else
                    docPhone += `, ${currentDoctor.phoneNumbers[i].text}`
            }
            setDocPhone(docPhone)

            let docDegree = ""
            for (let i in currentDoctor.qualifications){

                if(docDegree === "")
                    docDegree += currentDoctor.qualifications[i].text
                else
                    docDegree += `, ${currentDoctor.qualifications[i].text}`
            }
            setDocDegree(docDegree)

            let docSpec = ""
            for (let i in currentDoctor.specializations){

                if(docSpec === "")
                    docSpec += currentDoctor.specializations[i].text
                else
                    docSpec += `, ${currentDoctor.specializations[i].text}`
            }
            setDocSpec(docSpec)
        }
    },[currentDoctor])

    return(
        <div className={"md:p-4 p-2"}>
            <div className={"bg-white md:w-2/3 w-5/6 m-auto p-4 rounded-xl shadow-lg align-center"}>
                <div className={"bg-green-50 mt-4 mb-4 pt-8 pb-8"}>
                    <div className={"font-bold text-green-600 text-2xl"}>Doctor's Details</div>
                    <TextField
                        label={"Dr. Name"}
                        required
                        fullWidth
                        value={docName}
                        onChange={(e)=>{setDocName(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Dr. Qualification"}
                        required
                        fullWidth
                        value={docDegree}
                        onChange={(e)=>{setDocDegree(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Dr. Specialization"}
                        required
                        fullWidth
                        value={docSpec}
                        onChange={(e)=>{setDocSpec(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Dr. Contact"}
                        required
                        fullWidth
                        value={docPhone}
                        onChange={(e)=>{setDocPhone(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                </div>
                <div className={"bg-blue-50 mt-4 mb-4 pt-8 pb-8"}>
                    <div className={"font-bold text-blue-500 text-2xl"}>Patient Details</div>
                    <TextField
                        label={"Name"}
                        required
                        fullWidth
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Age"}
                        required
                        fullWidth
                        value={age}
                        onChange={(e)=>{setAge(e.target.value)}}
                        multiline={false}
                        inputMode={"numeric"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Gender"}
                        required
                        fullWidth
                        value={gender}
                        onChange={(e)=>{setGender(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Weight"}
                        required
                        fullWidth
                        value={weight}
                        onChange={(e)=>{setWeight(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Blood Pressure"}
                        required
                        fullWidth
                        value={bloodPressure}
                        onChange={(e)=>{setBloodPressure(e.target.value)}}
                        multiline={false}
                        inputMode={"text"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <TextField
                        label={"Contact"}
                        required
                        fullWidth
                        value={contact}
                        onChange={(e)=>{setContact(e.target.value)}}
                        multiline={false}
                        inputMode={"tel"}
                        variant={"filled"}
                        style={{marginTop:"12px", width:"80%"}}
                    />
                    <div className={"pt-4 font-bold text-lg"}>Tests</div>
                    <textarea
                        required
                        value={tests}
                        onChange={(e)=>{setTests(e.target.value)}}
                        style={{marginTop:"12px",
                            width:"80%",
                            height:"200px",
                            padding:"8px",
                            whiteSpace:"pre-wrap",
                            background:"#ffffff",
                            borderRadius:"8px 8px 0 0",
                            borderColor:"#000",
                            borderBottomWidth:"1px"
                        }}/>
                    <div className={"pt-4 font-bold text-lg"}>Prescription</div>
                    <textarea
                        required
                        value={prescription}
                        onChange={(e)=>{setPrescription(e.target.value)}}
                        style={{marginTop:"12px",
                            width:"80%",
                            height:"200px",
                            padding:"8px",
                            whiteSpace:"pre-wrap",
                            background:"#ffffff",
                            borderRadius:"8px 8px 0 0",
                            borderColor:"#000",
                            borderBottomWidth:"1px"
                        }}/>
                </div>
                <div>
                    <ReactToPrint content={() => ref.current}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button variant={"contained"} size={"large"} style={{
                                    padding:"12px 56px 12px 56px",
                                    marginTop:"32px",
                                    marginBottom:"12px"}}
                                        onClick={handlePrint}>Print</Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
            </div>
            <div className={"m-auto mt-8 md:w-2/3 w-5/6 p-4 bg-white rounded-xl shadow-xl"}>
                <div className={"font-bold text-red-500 text-2xl"}>Preview</div>
                <Prescription
                    ref={ref}
                    name={name}
                    age={age}
                    gender={gender}
                    weight={weight}
                    bloodPressure = {bloodPressure}
                    contact={contact}
                    doctorName={docName}
                    doctorDegree={docDegree}
                    doctorSpec={docSpec}
                    doctorPhone={docPhone}
                    tests = {tests}
                    prescription = {prescription}
                />
            </div>
        </div>
    )
}
