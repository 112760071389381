import {useNavigate} from "react-router";
import {getAuthUser} from "../firebase/check";
import {useEffect, useState} from "react";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Add, Refresh} from "@mui/icons-material";
import profilePic from "../raw/ic/profile.png";
import {fetchClinicList} from "../firebase/fetch/clinics";
import DeleteClinics from "../firebase/alter/DeleteClinics";

export default function Clinics(){
    const navigator = useNavigate();
    const [docList, setDocList] = useState([])
    const [docTableList, setDocTableList] = useState([])

    useEffect(()=>{
        refreshData()
    },[])

    useEffect(()=>{
        setDocTableList(docList)
        return ()=>{}
    },[docList])

    const refreshData = () =>{
        fetchClinicList().then(
            data =>{
                setDocList(data)
            }
        ).catch(err=>{
            alert(err.message)
        })
    }

    return(
        <div>
            <div className={"fixed right-8 bottom-8"} >
                <Button variant={"contained"} startIcon={<Add/>} style={{padding:"10px 20px 10px 20px"}}
                        onClick={()=>{navigator("/add-clinic")}}>
                    Add Clinic
                </Button>
                <Button variant={"contained"} startIcon={<Refresh/>} style={{padding:"10px 20px 10px 20px", margin:"0 0 0 10px"}}
                        onClick={()=>{refreshData()}}>
                    Refresh
                </Button>
            </div>

            <div className={"m-2 shadow-md rounded-md pb-16 bg-gray-100"}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="Doctor's Table">
                        <TableHead className={"font-bold text-2xl"}>
                            <TableRow style={{backgroundColor:"#99bbd7"}}>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Contact</TableCell>
                                <TableCell align="left">State</TableCell>
                                <TableCell align="left">Address</TableCell>
                                <TableCell align="left">About</TableCell>
                                <TableCell align="left">Maps Link</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                docTableList.map((data)=>(
                                    <TableRow className={"hover:bg-blue-100"}>
                                        <TableCell align={"left"} style={{width:"16.7%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold"}>{data.name}</div>
                                            <div className={"p-2 mt-2 rounded-md bg-green-100 text-md font-bold"}>{data.city}</div>
                                        </TableCell>
                                        <TableCell align={"left"}>
                                            <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold break-words"}>{data.contact}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"16.7%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold"}>{data.state}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"16.7%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold"}>{data.address}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"16.7%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold text-ellipsis leading-6 max-h-28 overflow-hidden block"}>{data.about}</div>
                                        </TableCell>
                                        <TableCell>
                                            <a href={data.mapsLink} target="_blank">
                                                <div className={"p-2 w-40 rounded-md bg-blue-500 text-white text-center text-lg font-bold"}>Open in Maps</div>
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                                <div className={"p-2 pl-8 pr-8 rounded-md bg-red-600 text-white text-center text-lg font-bold cursor-pointer"}
                                                onClick={async () => {
                                                    if (window.confirm("Are you sure you want to delete this record")) {
                                                        await DeleteClinics(data.id)
                                                        await alert("Record deleted successfully!")
                                                        refreshData()
                                                    }
                                                }}>Delete</div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>
    )
}
