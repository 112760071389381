import profilePic from "../raw/ic/profile.png"
import {useNavigate, useParams} from "react-router";
import {fetchDoctorByID} from "../firebase/fetch/doctor";
import {useState} from "react";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {deleteDoc} from "firebase/firestore";
import DeleteDoctor from "../firebase/alter/DeleteDoctor";

export default function DoctorDetails(props){
    const {id} = useParams()

    const navigator = useNavigate()
    const [details, setDetails] = useState(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    fetchDoctorByID(id).then((data)=>{
        setDetails(data)
    }).catch((reason)=>{
        alert(reason)
    })

    return(
        <div style={{minWidth:"350px"}}>
            <div className={"flex flex-col md:flex-row flex-nowrap p-4"}>
                <div className={"rounded-lg p-8 md:pt-16 md:pl-16 w-1/3"}>
                    <img src={(details!==null)?(details.profilePic)?details.profilePic:profilePic:profilePic} alt={"profile.pic"} style={{borderRadius:"8px", maxWidth:"180px", maxWeight:"180px", width:"180px", height:"180px", filter:"drop-shadow(-50px 50px 40px #80ADF6)"}} />
                </div>
                <div className={"flex flex-col flex-nowrap"}>
                    <div className={"p-4 mt-32 md:mt-4 md:pt-8 m-4 text-left"}>
                        <div className={"p-4 shadow-md rounded-lg"}>
                            <span className={"text-xl"}><AssignmentIndIcon style={{margin:"0 5px 0 5px", fontSize:"1em"}}/> Record ID: <span className={"text-green-600 font-bold"}>{(details!==null)?details.id:"N/A"}</span></span>
                            <span className={"text-bold text-3xl font-bold text-blue-500 m-2 ml-4"}><LocalHospitalIcon style={{fontSize:"1.3em", color:"green"}}/> {(details!==null)?details.name:"N/A"}</span>
                            <div className={"flex flex-col md:flex-row flex-wrap"}>
                                {
                                    (details===null)?"N/A":
                                        details.specializations.map((specialization)=> (
                                            <div className={"p-2 m-2 rounded-md text-sm bg-gray-200 font-bold justify-start"}>
                                                <span>{specialization.text}</span>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={"flex flex-col md:flex-row mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Reg No.:</span>
                                <div className={"p-2 m-2 text-bold text-white font-bold rounded-md text-sm bg-green-600 justify-start"}>
                                    <span>{(details!==null)?details.registrationNumber:"N/A"}</span>
                                </div>
                            </div>
                            <div className={"flex flex-col md:flex-row flex-wrap mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Phone:</span>
                                {
                                    (details===null)?"N/A":
                                        details.phoneNumbers.map((phone)=> (
                                            <div className={"p-2 m-2 rounded-md text-sm bg-gray-200 justify-start"}>
                                                <span>{phone.text}</span>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={"flex flex-col md:flex-row flex-wrap mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Email:</span>
                                {
                                    (details===null)?"N/A":
                                        details.emails.map((email)=> (
                                            <div className={"p-2 m-2 rounded-md text-sm bg-gray-200 justify-start"}>
                                                <span>{email.text}</span>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={"flex flex-col md:flex-row flex-wrap mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Qualification:</span>
                                {
                                    (details===null)?"N/A":
                                        details.qualifications.map((qualification)=> (
                                            <div className={"p-2 m-2 rounded-md text-sm bg-gray-200 justify-start"}>
                                                <span>{qualification.text}</span>
                                            </div>
                                        ))
                                }
                            </div>
                            <div className={"flex flex-col flex-wrap md:flex-row mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Address:</span>
                                <div className={"p-2 m-2 rounded-md text-sm bg-gray-200 justify-start"}>
                                    <span>{(details!==null)?details.address:"N/A"}</span>
                                </div>
                            </div>
                            <div className={"flex flex-col flex-wrap md:flex-row mt-4"}>
                                <span className={"text-lg font-bold m-2"}>Age:</span>
                                <div className={"p-2 m-2 text-bold rounded-md text-sm bg-gray-200 justify-start"}>
                                    <span>{(details!==null)?details.age:"N/A"}</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={"p-4 ml-4 mr-4 text-left"}>
                        <div className={"p-4 mb-6 shadow-md rounded-lg"}>
                            <span className={"text-xl font-bold mb-4"}>About</span><br/>
                            <span className={""}>{(details!==null)?details.about:"N/A"}</span>
                        </div>
                    </div>


                    <div className={"p-4 ml-4 mr-4 text-left"}>
                        <div className={"p-4 mb-6 shadow-md rounded-lg"}>
                            <span className={"text-xl font-bold"}>Actions</span><br/>
                            <Button variant={"contained"} style={{margin:"5px 0"}} startIcon={<Edit/>}
                            onClick={()=>{
                                navigator(`/add-doctor/${id}/${true}`)
                        }}>Edit</Button>
                            <Button variant={"contained"}
                                    color={"error"}
                                    style={{margin:"5px 10px"}}
                                    onClick={()=>{
                                        if(!deleteDialogOpen)
                                            setDeleteDialogOpen(true)
                        }}
                                    startIcon={<Delete/>}>Delete</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={()=>{}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this record?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action can't be undone. If you delete this record, the data will be deleted from the server permanently. Are you sure you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDeleteDialogOpen(false)}}>No</Button>
                    <Button onClick={async () => {
                        navigator("/")
                        DeleteDoctor(id).then(_=>{
                            alert("Deleted successfully!")
                        }).catch((err) =>{
                            alert(err.message)
                        })
                    }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
