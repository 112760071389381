import { collection, doc, getDocs, getDoc, getFirestore } from "firebase/firestore";

/**
 * Fetch Doctors list
 * @returns {Promise<*[]>}
 */
export async function fetchClinicList() {
    const docList = []
    const db = getFirestore()
    const docListRef = collection(db, "clinics")
    const dataSnapshot = await getDocs(docListRef)
    await dataSnapshot.forEach((doc) => {
        let data = doc.data()
        data = {... data, id: doc.id}
        docList.push(data)
    })
    return docList
}
