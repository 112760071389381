import React, { forwardRef } from "react";
import logo from "../raw/logo/logo.svg"

export const Prescription = forwardRef((props, ref) => {
    const name = props.name
    const age = props.age
    const gender = props.gender
    const weight = props.weight
    const bloodPressure = props.bloodPressure
    const contact = props.contact
    const docName = props.doctorName
    const docDegree = props.doctorDegree
    const docSpec = props.doctorSpec
    const docPhone = props.doctorPhone
    const tests = props.tests
    const prescription = props.prescription

    return (
        <div ref={ref} className={"w-full h-full p-4"}>
            <div className={"flex flex-row-reverse justify-between pl-8 pr-8"}>
                <img src={logo} alt={"logo"} width={"220"}/>
                <div className={"p-4 text-left"}>
                    <div className={"text-2xl font-bold"}>{docName}</div>
                    <div className={"text-md font-bold"}>{docDegree}</div>
                    <div className={"text-md font-bold"}>{docSpec}</div>
                    <div className={"text-md"}>{docPhone}</div>
                </div>
            </div>
            <div className={"flex flex-col text-left pl-12 pr-12 mt-8 mb-12"}>
                <div className={"text-lg mb-4"}>Date: {new Date().toLocaleDateString("pt-PT")}</div>
                <div className={"rounded-lg border-2 bg-gray-100"}>
                    <div className={"flex flex-row content-center items-center "}>
                        <div className={"bg-gray-100 p-2 border-b-2 w-1/6"}>Name</div>
                        <div className={"p-2 bg-white border-b-2 w-5/6"}>{name?name:"\xa0"}</div>
                    </div>
                    <div className={"grid grid-cols-2 grid-flow-cols"}>
                        <div className={"flex flex-row content-center items-center"}>
                            <div className={"bg-gray-100 p-2 border-b-2 w-1/3"}>Age</div>
                            <div className={"p-2 bg-white border-b-2 w-2/3"}>{age?age:"\xa0"}</div>
                        </div>
                        <div className={"flex flex-row content-center items-center"}>
                            <div className={"bg-gray-100 p-2 border-b-2 w-1/3 border-l-2"}>Gender</div>
                            <div className={"p-2 bg-white border-b-2 w-2/3"}>{gender?gender:"\xa0"}</div>
                        </div>
                    </div>
                    <div className={"grid grid-cols-2 grid-flow-cols"}>
                        <div className={"flex flex-row content-center items-center"}>
                            <div className={"bg-gray-100 p-2 border-b-2 w-1/3"}>Weight</div>
                            <div className={"p-2 bg-white border-b-2 w-2/3"}>{weight?weight:"\xa0"}</div>
                        </div>
                        <div className={"flex flex-row content-center items-center"}>
                            <div className={"bg-gray-100 p-2 border-b-2 w-1/3 border-l-2"}>Blood Pressure</div>
                            <div className={"p-2 bg-white border-b-2 w-2/3"}>{bloodPressure?bloodPressure:"\xa0"}</div>
                        </div>
                    </div>
                    <div className={"flex flex-row content-center items-center"}>
                        <div className={"bg-gray-100 p-2 w-1/6"}>Contact</div>
                        <div className={"p-2 bg-white w-5/6"}>{contact?contact:"\xa0"}</div>
                    </div>
                </div>
                <div className={"rounded-lg border-2 mt-4"}>
                    <div className={"bg-gray-100 mb-2 p-2 font-bold"}>
                        Tests prescribed
                    </div>
                    <div className={"text-lg mb-4 whitespace-pre-wrap p-4"}>
                        {tests?tests:"\xa0"}
                    </div>
                </div>
                <div className={"text-lg mt-8 mb-12 whitespace-pre-wrap"}>{prescription}</div>
                <div className={"text-right"}>
                    <div className={""}>_______________________________</div>
                    <div>Doctor's Signature</div>
                </div>
            </div>
            <div className={"h-0.5 bg-gray-500 m-8"}></div>
            <div className={"w-full mb-8 text-sm text-center"}>
                For emergency please contact any State Hospital/Local Doctor. This document can only be issued by a Dr. Roy's Homoeopathy approved clinic/clinics. In case of any infringement or misuse of this document, the medical institute can take legal actions on the other party/parties involved.
                {/*This document is a critical medical record. Please handel it carefully. Make sure that the prescription has the doctor's stamp/signature. Do not accept this document without a valid Dr. Roy's Homoeopathy stamp/signature. This document can only be issued by a Dr. Roy's Homoeopathy approved clinic. In case of any infringement or misuse of this document, the medical institute can take legal actions on the other party/parties involved.*/}
            </div>
        </div>);
});
