import {useNavigate} from "react-router";
import Switch from '@mui/material/Switch';
import {getAuthUser} from "../firebase/check";
import {
    Button,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import profilePic from "../raw/ic/profile.png";
import {useEffect, useState} from "react";
import {fetchAppointmentList} from "../firebase/fetch/appointment";
import {Search} from "@mui/icons-material";

export default function Appointments(){
    const navigator = useNavigate();

    const [appointmentList, setAppointmentList] = useState([])
    const [appointmentTableList, setAppointmentTableList] = useState([])
    const [searchKey, setSearchKey] = useState("")
    const [pendingFilter, setPendingFilter] = useState(false)

    useEffect(()=>{
        let searchList = []
        if(pendingFilter){
            appointmentList.forEach(appointment=>{
                if(!Boolean(appointment.status))
                    searchList.push(appointment)
            })
        }else searchList = appointmentList
        if(searchKey !== ""){
            const finalList = []
            searchList.forEach(data=>{
                if(data.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.id.includes(searchKey) ||
                    data.phone.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.email.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.gender.toLowerCase() === searchKey.toLowerCase() ||
                    data.address.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.issue.toLowerCase().includes(searchKey.toLowerCase()) ||
                    data.age == searchKey)
                    finalList.push(data)
            })
            setAppointmentTableList(finalList)
        }else{
            setAppointmentTableList(searchList)
        }
    },[searchKey, pendingFilter])

    useEffect(()=>{
        refreshData()
    },[])

    useEffect(()=>{
        setAppointmentTableList(appointmentList)
        return ()=>{}
    },[appointmentList])

   function refreshData(){
        fetchAppointmentList().then(data=>{
            data.sort((a,b)=>(a.registeredOn.toDate() <= b.registeredOn.toDate())? 1: -1)
            setAppointmentList(data)
        }).catch(err=>{
            alert(err.message)
        })
    }

    return(
        <div className={""}>
            <div className={"p-4 m-2 shadow-md bg-white rounded-md flex flex-row"}>
                <TextField
                    label="Search"
                    fullWidth
                    type="text"
                    style={{fontSize:"1.2em"}}
                    onChange={(e)=>{setSearchKey(e.target.value)}}
                    value={searchKey}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <div className={"p-2 ml-8 bg-red-50 rounded-md grid grid-row-1 grid-flow-col"}>
                    <span className={"m-auto"}>Pending:</span>
                    <Switch color={"error"}
                            checked={pendingFilter}
                            onChange={(e)=> {
                                setPendingFilter(e.target.checked)
                            }}/>
                </div>
                <Button variant={"contained"}
                        style={{margin:"0 20px 0 20px", padding:"0 30px"}}
                        onClick={()=>{
                            setSearchKey("")
                            setPendingFilter(false)
                        }}>Clear</Button>
            </div>
            <div className={"m-2 shadow-md rounded-md pb-16 bg-gray-100"}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="Doctor's Table">
                        <TableHead className={"font-bold text-2xl"}>
                            <TableRow style={{backgroundColor:"#99bbd7"}}>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Gender</TableCell>
                                <TableCell align="left">Age</TableCell>
                                <TableCell align="left">Phone</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                appointmentTableList.map((data)=>(
                                    <TableRow className={"hover:bg-blue-100 cursor-pointer"} onClick={()=>{navigator(`/appointment/${data.id}`)}}>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-gray-100 text-lg font-bold"}>{(data.registeredOn !== null)?data.registeredOn.toDate().toLocaleString("pt-PT"):"N/A"}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-blue-600 text-white text-lg font-bold"}>{data.name}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-yellow-100 text-lg font-bold"}>{data.gender}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-yellow-100 text-lg font-bold"}>{data.age}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-blue-500 text-white text-lg font-bold"}>{data.phone}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={"p-2 mb-2 rounded-md bg-yellow-100 text-lg font-bold"}>{(data.email)?data.email:"N/A"}</div>
                                        </TableCell>
                                        <TableCell align={"left"} style={{width:"14.28%"}}>
                                            <div className={`p-2 mb-2 rounded-md ${(data.status)?"bg-green-600": "bg-red-600"} text-white text-lg font-bold`}>{(data.status)?"Attended":"Pending"}</div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
