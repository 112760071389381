import {collection, doc, getDoc, getDocs, getFirestore} from "firebase/firestore";

export async function fetchAppointmentList() {
    const appointmentList = []
    const db = getFirestore()
    const appointmentListRef = collection(db, "appointment-request")
    const dataSnapshot = await getDocs(appointmentListRef)
    await dataSnapshot.forEach((doc) => {
        let data = doc.data()
        data = {... data, id: doc.id}
        appointmentList.push(data)
    })
    return appointmentList
}

export async function fetchAppointmentByID(id){
    return new Promise(async (resolve, reject) => {
        const db = getFirestore()
        const docRef = doc(db, "appointment-request", id)
        const dataSnapshot = await getDoc(docRef)
        if (dataSnapshot.exists()) {
            resolve(dataSnapshot.data())
        } else {
            reject("Record doesn't exists")
        }
    })
}
