import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {loginUser} from "./firebase/auth";
import logo from "./raw/logo/logo.svg"
import platonicc_logo from "./raw/logo/platonicc_logo.png";
import {ArrowRight, Email, GitHub, Phone, Support, SupportAgent} from "@mui/icons-material";

export default function Auth(){
    //Data models
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const onLoginClicked = ()=>{
        if(email)
            if(password)
                loginUser(email,password)
                    .then((user)=>{console.log(user)})
                    .catch((error)=>{setErrorMessage(error.message)})
        else setErrorMessage("Enter a valid password!")
        else setErrorMessage("Enter a valid email!")
    }

    return(
        <div style={{width: "100vw", height:"auto", backgroundColor:"teal"}}>
            <div className={"h-full w-full flex flex-row content-center justify-center text-center lg:p-8"}>
                <div className={"bg-white md:rounded-lg lg:w-3/5 w-full h-full shadow-lg p-8 pb-12"}>
                    <img src={logo} alt={"company_logo"} style={{margin:"auto", width:"40%"}}/><br/><br/>
                    <span className={"font-bold text-2xl "}>Admin Login</span><br/><br/>
                    <TextField
                        variant={"filled"}
                        label={"Email"}
                        style={{width:"60%"}}
                        onChange={(e)=>{setEmail(e.target.value)}}/>
                    <br/><br/>
                    <TextField
                        variant={"filled"}
                        label={"Password"}
                        type={"password"}
                        onChange={(e)=>{setPassword(e.target.value)}}
                        style={{width:"60%"}}/>
                    <br/><br/>
                    <Button
                        label={"Login"}
                        variant={"contained"}
                        style={{width:"60%", padding:"2%"}}
                        onClick={onLoginClicked}>Login</Button>
                    <br/><br/><br/>
                    <div className={"text-red-600"}>{errorMessage}</div>
                    <div className={"pt-4"}>
                            <div className={"text-xl w-auto flex flex-row content-center justify-end items-center p-2 pr-16"}>
                                <img src={platonicc_logo} alt={"platonicc.png"} width={"36"}/>
                                <div className={"text-right"}>
                                    <a href={"https://github.com/Platonicc"} target={"_blank"}>
                                        <span className={"ml-8 text-xs mb-2"}>Powered by</span><br/>
                                        <span className={"ml-2"}>Platonicc OSS</span><br/>
                                        <span className={"ml-8 text-xs mb-2"}><GitHub/></span>
                                    </a>
                                </div>
                            </div>
                    </div>
                    <div className={"text-gray-400"}>
                        <div className={"font-bold text-lg mb-2"}>
                            <SupportAgent/> Support </div>
                        <Phone/> +91 (0) 7909048102 <ArrowRight/>
                        <Email/> asdevofficial@gmail.com
                    </div>
                </div>
            </div>
        </div>
    )
}
