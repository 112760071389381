import {useNavigate} from "react-router";
import {getAuthUser} from "../firebase/check";
import {
    Button,
    IconButton, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {Add, Refresh, Search} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {fetchDoctorList} from "../firebase/fetch/doctor";
import profilePic from "../raw/ic/profile.png"

export default function DoctorRecords(){
    const navigator = useNavigate();
    const [docTableList, setDocTableList] = useState([])
    const [docList, setDocList] = useState([])
    const [searchKey, setSearchKey] = useState("")

    useEffect(()=>{
        refreshData()
        return()=>{}
    },[])

    useEffect(()=>{
       setDocTableList(docList)
        return()=>{}
    },[docList])

    useEffect(()=>{
        const list = []
        docList.forEach((data)=>{
            if( data.id.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.about.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.about.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.address.toLowerCase().includes(searchKey.toLowerCase()) ||
                data.age == searchKey ||
                search(data.phoneNumbers, searchKey) ||
                search(data.emails, searchKey) ||
                search(data.qualifications, searchKey) ||
                search(data.specializations, searchKey)){
                list.push(data)
            }
        })
        setDocTableList(list)
    },[searchKey])

    function refreshData(){
        fetchDoctorList().then((list) =>{
            setDocList(list)
        }).catch((err)=>{
            console.log(err.message)
        })
    }

    function search(list, key){
        for(let i = 0; i<list.length; i++){
            if(list[i].text.toLowerCase().includes(key.toLowerCase()))
                return true
        }
        return false
    }


    return(
        <div className={"bg-white w-full h-full pr-4 pl-4"}>
            <div className={"fixed right-8 bottom-8"} >
                <Button variant={"contained"} startIcon={<Add/>} style={{padding:"10px 20px 10px 20px"}}
                onClick={()=>{navigator("/add-doctor")}}>
                    Add doctor
                </Button>
                <Button variant={"contained"} startIcon={<Refresh/>} style={{padding:"10px 20px 10px 20px", margin:"0 0 0 10px"}}
                        onClick={()=>{refreshData()}}>
                    Refresh
                </Button>
            </div>
            <div className={"p-4 m-2 shadow-md bg-white rounded-md flex flex-row"}>
                <TextField
                    label="Search"
                    fullWidth
                    type="text"
                    style={{fontSize:"1.2em"}}
                    onChange={(e)=>{setSearchKey(e.target.value)}}
                    value={searchKey}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
                <Button variant={"contained"}
                        style={{margin:"0 20px 0 40px", padding:"0 30px"}}
                        onClick={()=>{
                            setSearchKey("")
                        }}>Clear</Button>
            </div>
            <div className={"m-2 shadow-md rounded-md pb-16 bg-gray-100"}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="Doctor's Table">
                        <TableHead className={"font-bold text-2xl"}>
                            <TableRow style={{backgroundColor:"#99bbd7"}}>
                                <TableCell style={{width: "100px"}} align="left">Profile</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Phone</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Specialization</TableCell>
                                <TableCell align="left">Qualification</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                docTableList.map((data)=>(
                                <TableRow className={"hover:bg-blue-100 cursor-pointer"} onClick={()=>{navigator(`/doctor-details/${data.id}`)}}>
                                    <TableCell style={{width: "100px"}} align={"left"}>
                                        <img alt="profile.pic" style={{height:"80px !important", width:"80px !important", borderRadius:"3px"}} src={data.profilePic?data.profilePic:profilePic}/></TableCell>
                                    <TableCell align={"left"} style={{width:"16.7%"}}>
                                        <div className={"p-2 mb-2 rounded-md bg-green-600 text-white text-lg font-bold"}>{data.name}</div>
                                        <div className={"p-2 mt-2 rounded-md bg-green-100 text-md font-bold"}>{data.id}</div>
                                    </TableCell>
                                    <TableCell align={"left"}>
                                        <div className={"flex flex-row flex-wrap"}>
                                            {data.phoneNumbers.map((phone)=>(
                                                    <div className={"p-2 m-2 bg-gray-200 rounded-md font-bold"}>{phone.text}</div>))
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell align={"left"} style={{width:"16.7%"}}>
                                        <div className={"flex flex-row flex-wrap"}>
                                            {
                                                data.emails.map((email)=>(
                                                    <div className={"p-2 m-2 bg-gray-200 rounded-md font-bold"}>{email.text}</div>))
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell align={"left"} style={{width:"16.7%"}}>
                                        <div className={"flex flex-row flex-wrap"}>
                                            {
                                                data.specializations.map((specialization)=>(
                                                    <div className={"p-2 m-2 bg-gray-200 rounded-md font-bold"}>{specialization.text}</div>))
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell align={"left"} style={{width:"16.7%"}}>
                                        <div className={"flex flex-row flex-wrap"}>
                                            {
                                                data.qualifications.map((qualification)=>(
                                                    <div className={"p-2 m-2 bg-gray-200 rounded-md font-bold"}>{qualification.text}</div>))
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
