import { collection, doc, getDocs, getDoc, getFirestore } from "firebase/firestore";

/**
 * Fetch Doctors list
 * @returns {Promise<*[]>}
 */
export async function fetchDoctorList() {
    const docList = []
    const db = getFirestore()
    const docListRef = collection(db, "doctors")
    const dataSnapshot = await getDocs(docListRef)
    await dataSnapshot.forEach((doc) => {
        docList.push(doc.data())
    })
    return docList
}

export async function fetchDoctorByID(id){
    return new Promise(async (resolve, reject) => {
        const db = getFirestore()
        const docRef = doc(db, "doctors", id)
        const dataSnapshot = await getDoc(docRef)
        if (dataSnapshot.exists()) {
            resolve(dataSnapshot.data())
        } else {
            reject("Record doesn't exists")
        }
    })
}
