import {getFirestore} from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";


export default function AddClinicRecord(data={name:"", about:"", city:"", state:"", address:"", contact:"", mapsLink:""}){
    return new Promise(async (resolve, reject) => {
        const database = getFirestore()
        addDoc(collection(database, "clinics"),data).then((ref)=>{
            resolve(ref)
        }).catch(err=>{
            reject(err)
        })

    })
}
