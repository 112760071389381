import {doc, getFirestore, deleteDoc} from "firebase/firestore";
import {getStorage, deleteObject, ref} from "firebase/storage";

export default async function DeleteClinics(id) {
    return new Promise(async (resolve, reject) => {
        const db = getFirestore()
        const docRef = doc(db, "clinics", id)
        await deleteDoc(docRef)
        resolve(true)
    })
}
