import {Button, TextField} from "@mui/material";
import {useState} from "react";
import {Save} from "@mui/icons-material";
import AddClinicRecord from "../firebase/upload/AddClinicRecord";
import {useNavigate} from "react-router";

export default function AddClinic(){
    const [name, setName] = useState("")
    const [about, setAbout] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [address, setAddress] = useState("")
    const [contact, setContact] = useState("")
    const [mapsLink, setMapsLink] = useState("")

    const navigator = useNavigate()

    const inputValidation = ()=>{
        if(name)
            if(about)
                if(city)
                    if(state)
                        if(address)
                            if(contact)
                                if(mapsLink)
                                    return true
                                else alert('Input valid maps url')
                            else alert('Input valid contact')
                        else alert('Input valid address')
                    else alert('Input valid state')
                else alert('Input valid city')
            else alert('Input valid maps about')
        else alert('Input valid maps name')
        return false
    }

    const uploadData = ()=>{
        const data = {
            name: name,
            about: about,
            city: city,
            state: state,
            address: address,
            contact: contact,
            mapsLink: mapsLink
        }
        AddClinicRecord(data).then((ref)=>{
            alert("Record added successfully.")
            navigator("/clinics")
        }).catch(err=>{
            alert(err.message)
        })
    }


    return(
        <div className={"p-4"}>
            <div className={"bg-white rounded-lg shadow-lg md:m-auto m-8 p-4 text-left md:w-1/2"}>
                <div className={"text-center"}>
                    <span className={"font-bold text-2xl m-8 text-center bg-blue-100 text-blue-500 rounded-lg p-4 w-full"}>Add new Clinic</span><br/>
                </div>
                <TextField
                    label={"Clinic Name"}
                    required
                    fullWidth
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"About"}
                    required
                    fullWidth
                    value={about}
                    onChange={(e)=>{setAbout(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"City"}
                    required
                    fullWidth
                    value={city}
                    onChange={(e)=>{setCity(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"State"}
                    required
                    fullWidth
                    value={state}
                    onChange={(e)=>{setState(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"Address"}
                    required
                    fullWidth
                    value={address}
                    onChange={(e)=>{setAddress(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"Contact"}
                    required
                    fullWidth
                    value={contact}
                    onChange={(e)=>{setContact(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <TextField
                    label={"Google Maps Link"}
                    required
                    fullWidth
                    value={mapsLink}
                    onChange={(e)=>{setMapsLink(e.target.value)}}
                    style={{marginTop:"32px"}}
                />
                <div className={""}>
                    <Button variant={"contained"}
                            color={"success"}
                            startIcon={<Save/>}
                            onClick={()=>{
                                 if(inputValidation())
                                     uploadData()
                            }}
                            style={{padding:"15px 20px 15px 20px",margin:"20px 0 20px 0", width:"50%"}}>Save</Button>
                </div>
            </div>
        </div>
    )
}
