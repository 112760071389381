import { doc, setDoc, getFirestore } from "firebase/firestore"

/**
 *
 *
 * @param data
 * @returns {Promise<unknown>}
 * @constructor
 */
export default function AddDoctorRecord(data = {
    id:"",
    name:"",
    age:0,
    address:"",
    registrationNumber:"",
    about:"",
    phoneNumbers:[],
    emails:[],
    qualification:[],
    specialization:[],
    profilePic:"",
    registeredOn:Date.now(),
    lastEditedOn: Date.now()}){
    return new Promise((resolve, reject)=>{
        const database = getFirestore()
        setDoc(doc(database, "doctors", data.id),data).then(()=>{
            resolve("successful")
        }).catch(err=>{
            reject(err)
        })
    })
}
