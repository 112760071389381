import {Button, TextField} from "@mui/material";
import {Clear, Cloud, Delete, Image, Save} from "@mui/icons-material";
import profile from "../raw/ic/profile.png"
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import "./AddDoctorStyle.css"
import generateUniqueId from "generate-unique-id"
import UploadProfilePic from "../firebase/upload/UploadProfilePic";
import AddDoctorRecord from "../firebase/upload/AddDoctorRecord";
import {fetchDoctorByID} from "../firebase/fetch/doctor";
import {useNavigate, useParams} from "react-router";

export function AddDoctor(){
    const {id, edit} = useParams()
    const navigator = useNavigate()

    const [recordID, setRecordID] = useState('')

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const [isAddDocMounted, setIsDocMounted] = useState(false)
    const [name, setName] = useState("")
    const [age, setAge] = useState(0)
    const [address, setAddress] = useState("")
    const [registrationNumber, setRegistrationNumber] = useState("")
    const [about, setAbout] = useState("")
    const [error, setError] = useState("")

    const [editDetails, setEditDetails] = useState(null)
    const [profilePictureFile, setProfilePictureFile] = useState(null)
    const [profilePictureSrc, setProfilePictureSrc] = useState(null)
    const [profilePicUploadBtnDisable, setProfilePicUploadBtnDisable] = useState(false)
    const [profilePicDeleteBtnDisable, setProfilePicDeleteBtnDisable] = useState(true)
    const [phoneNumbers, setPhoneNumbers] = useState([])
    const [emails, setEmails] = useState([])
    const [qualifications, setQualifications] = useState([])
    const [specializations, setSpecializations] = useState([])

    useEffect(()=>{
        if(edit)
            setRecordID(id)
        else
            setRecordID(generateUniqueId({
                length: 10,
                useLetters: false
            }))

        if(edit){
            fetchDoctorByID(id).then((data)=>{
                setEditDetails(data)
                setProfilePictureSrc(data.profilePic)
                setName(data.name)
                setAge(data.age)
                setAddress(data.address)
                setRegistrationNumber(data.registrationNumber)
                setAbout(data.about)
                setPhoneNumbers(data.phoneNumbers)
                setEmails(data.emails)
                setQualifications(data.qualifications)
                setSpecializations(data.specializations)
            }).catch(err=>{
                alert(err.message)
            })
        }
    },[])

    useEffect(()=>{
        if(profilePictureSrc!==null){
            setProfilePicDeleteBtnDisable(false)
        }else{
            setProfilePicDeleteBtnDisable(true)
        }
        return()=>{
            setIsDocMounted(false)
        }
    },[profilePictureSrc])

    const validInput = () => {
        if(name)
            if(age>0)
                if(address)
                    if(registrationNumber > 0)
                        if(about)
                            if(phoneNumbers.length > 0)
                                if(emails.length > 0)
                                    if(qualifications.length > 0)
                                        if(specializations.length > 0){
                                            setError("")
                                            return true
                                        }
                                        else setError("Please enter specialization!")
                                    else setError("Please enter qualification!")
                                else setError("Please enter email!")
                            else setError("Please enter phone numbers!")
                        else setError("Please enter a valid about!")
                    else setError("Please enter a valid registration number!")
                else setError("Please enter a valid address!")
            else setError("Please enter a valid age!")
        else setError("Please enter a valid name!")
        return false
    }

    //--------------------- phone ---------------------
    const handlePhoneDelete = i => {
        setPhoneNumbers(phoneNumbers.filter((tag, index) => index !== i));
    };

    const handlePhoneAddition = phone => {
        setPhoneNumbers([...phoneNumbers, phone]);
    };

    const handlePhoneClearAll = _ =>{
        setPhoneNumbers([])
    }

    //--------------------- email ---------------------
    const handleEmailDelete = i => {
        setEmails(emails.filter((tag, index) => index !== i));
    };

    const handleEmailAddition = email => {
        setEmails([...emails, email]);
    };

    const handleEmailClearAll = _ =>{
        setEmails([])
    }

    //--------------------- qualification ---------------------
    const handleQualificationDelete = i =>{
        setQualifications(qualifications.filter((tag, index) => index !== i));
    };

    const handleQualificationAddition = qualification => {
        setQualifications([...qualifications, qualification]);
    };

    const handleQualificationClearAll = _ =>{
        setQualifications([])
    }

    //--------------------- specializations ---------------------
    const handleSpecializationDelete = i =>{
        setSpecializations(specializations.filter((tag, index) => index !== i));
    };

    const handleSpecializationAddition = specialization => {
        setSpecializations([...specializations, specialization]);
    };

    const handleSpecializationClearAll = _ =>{
        setSpecializations([])
    }

    const handleSelectProfileImageChange = async (e) => {
        await setProfilePictureFile(e.target.files[0])
        const reader = new FileReader()
        reader.onload = function (e) {
            setProfilePictureSrc(e.target.result)
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const handleRemoveProfileImage = ()=>{
        setProfilePictureSrc(null)
        setProfilePictureFile(null)
    }

    function uploadDoctorRecord() {
        return new Promise((resolve, reject)=>{
            if(profilePictureFile!==null){
                UploadProfilePic(profilePictureFile, recordID).then((url)=>{
                        AddDoctorRecord(
                            {
                                id: recordID,
                                name: name,
                                age: age,
                                address: address,
                                registrationNumber: registrationNumber,
                                about: about,
                                phoneNumbers: phoneNumbers,
                                emails: emails,
                                qualifications: qualifications,
                                specializations: specializations,
                                profilePic: url,
                            }
                        ).then(()=>{
                            resolve("successful")
                        }).catch(err=>{
                            console.log(err)
                            reject(err)
                        })
                }).catch(err=>{
                    console.log(err)
                    reject(err)
                })
            }else{
                if(edit){
                    AddDoctorRecord(
                        {
                            id: recordID,
                            name: name,
                            age: age,
                            address: address,
                            registrationNumber: registrationNumber,
                            about: about,
                            phoneNumbers: phoneNumbers,
                            emails: emails,
                            qualifications: qualifications,
                            specializations: specializations,
                            profilePic: editDetails.profilePic
                        }
                    ).then(()=>{
                        resolve("successful")
                    }).catch(err=>{
                        console.log(err)
                        reject(err)
                    })
                }else{
                    AddDoctorRecord(
                        {
                            id: recordID,
                            name: name,
                            age:age,
                            address: address,
                            registrationNumber: registrationNumber,
                            about: about,
                            phoneNumbers: phoneNumbers,
                            emails: emails,
                            qualifications: qualifications,
                            specializations: specializations
                        }
                    ).then(()=>{
                        resolve("successful")
                    }).catch(err=>{
                        console.log(err)
                        reject(err)
                    })
                }
            }
        })
    }

    return (
        <div className={"h-full w-full"}>
            <div className={"flex flex-row w-full h-full flex-nowrap"}>
                <div className={"w-2/5 h-full text-center align-center justify-center"}>
                    <br/><br/>
                    <img src={profilePictureSrc===null?profile:profilePictureSrc} alt={"Profile Picture"} style={{height:"160px", width:"160px", margin:"auto"}}/>
                    <br/>
                    <Button variant={"contained"} startIcon={<Image/>} style={{padding:"10px 20px 10px 20px"}}
                            disabled={profilePicUploadBtnDisable} component="label">
                        Select image
                        <input
                            accept="image/jpeg"
                            type="file"
                            onChange={handleSelectProfileImageChange}
                            hidden
                        />
                    </Button>
                    <Button variant={"contained"}
                            color={"error"}
                            startIcon={<Clear/>}
                            disabled={profilePicDeleteBtnDisable}
                            onClick={handleRemoveProfileImage}
                            style={{padding:"10px 20px 10px 20px", margin:"0 10px"}}>
                        Clear
                    </Button>
                </div>
                <div className={"w-full h-full text-left ml-10 mt-10"}>
                    <div className={"p-8 mb-4 shadow-md rounded-lg md:w-1/2"}>
                        <span className={"text-2xl"}><AssignmentIndIcon style={{margin:"0 5px 0 0", fontSize:"1.5em"}}/> Record ID: <span className={"text-red-500 font-bold"}>{recordID}</span></span>
                    </div><br/>
                    <div className={"bg-red-50 rounded-md p-6 md:w-1/2 mb-4"} style={{display:(error==="")?"none":"block"}}>
                        <span className={"text-red-600"}>{error}</span>
                    </div>
                    <span className={"font-bold text-lg"}>
                        Info
                    </span><br/>
                    <TextField
                        label={"Name"}
                        required
                        fullWidth
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        style={{width:"50%", marginTop:"8px"}}
                    /><br/><br/>
                    <TextField
                        label={"Age"}
                        required
                        fullWidth
                        value={age}
                        onChange={(e)=>{setAge(parseInt(e.target.value))}}
                        inputMode={"numeric"}
                        type={"number"}
                        style={{width:"50%", marginTop:"8px"}}
                    /><br/><br/>
                    <TextField
                        label={"Address"}
                        required
                        fullWidth
                        value={address}
                        onChange={(e)=>{setAddress(e.target.value)}}
                        multiline={true}
                        inputMode={"text"}
                        style={{width:"50%", marginTop:"8px"}}
                    /><br/><br/>
                    <TextField
                        label={"Registration no."}
                        required
                        fullWidth
                        value={registrationNumber}
                        onChange={(e)=>{setRegistrationNumber(e.target.value)}}
                        inputMode={"numeric"}
                        type={"number"}
                        style={{width:"50%", marginTop:"8px"}}
                    /><br/><br/>
                    <TextField
                        label={"About"}
                        required
                        fullWidth
                        value={about}
                        onChange={(e)=>{setAbout(e.target.value)}}
                        multiline={true}
                        inputMode={"text"}
                        style={{width:"50%", marginTop:"8px"}}
                    /><br/><br/><br/>
                    <div>
                         <span className={"font-bold text-lg"}>
                        Phone numbers
                    </span><br/>
                        <ReactTags
                            tags={phoneNumbers}
                            delimiters={delimiters}
                            handleDelete={handlePhoneDelete}
                            handleAddition={handlePhoneAddition}
                            onClearAll={handlePhoneClearAll}
                            inputFieldPosition="bottom"
                            autocomplete
                            editable={true}
                            clearAll={true}
                            placeholder={"Add phone"}
                        />
                    </div>
                    <br/>
                    <div>
                        <span className={"font-bold text-lg"}>
                            Emails
                        </span>
                        <ReactTags
                            tags={emails}
                            delimiters={delimiters}
                            handleDelete={handleEmailDelete}
                            handleAddition={handleEmailAddition}
                            onClearAll={handleEmailClearAll}
                            inputFieldPosition="bottom"
                            autocomplete
                            editable={true}
                            clearAll={true}
                            placeholder={"Add email"}
                        />
                    </div>
                   <br/>
                    <div>
                         <span className={"font-bold text-lg"}>
                        Qualifications
                    </span><br/>
                        <ReactTags
                            tags={qualifications}
                            delimiters={delimiters}
                            handleDelete={handleQualificationDelete}
                            handleAddition={handleQualificationAddition}
                            onClearAll={handleQualificationClearAll}
                            inputFieldPosition="bottom"
                            autocomplete
                            editable={true}
                            clearAll={true}
                            placeholder={"Add Qualification"}
                        />
                    </div>
                    <br/>
                    <div>
                         <span className={"font-bold text-lg"}>
                        Specializations
                    </span><br/>
                        <ReactTags
                            tags={specializations}
                            delimiters={delimiters}
                            handleDelete={handleSpecializationDelete}
                            handleAddition={handleSpecializationAddition}
                            onClearAll={handleSpecializationClearAll}
                            inputFieldPosition="bottom"
                            autocomplete
                            editable={true}
                            clearAll={true}
                            placeholder={"Add Specialization"}
                        />
                    </div>
                    <br/>
                    <div className={"mt-4 mb-4 p-4 md:w-1/2 bg-white border-2 rounded-md text-slate-500"}>
                        <Cloud style={{margin:"0 5px 0 0"}}/> All the information will stored on remote servers. <span className={"text-red-500"}>Don't include any sensitive information.</span> Confirm the details before uploading to the servers. You can edit the information later from the admin panel. In-case of any malfunction or error, contact the manufacturer with proper issue description.
                    </div>
                    <Button variant={"contained"}
                            color={"success"}
                            startIcon={<Save/>}
                            onClick={()=>{
                                if(validInput()){
                                    uploadDoctorRecord().then(async r => {
                                        await alert("Record added!")
                                        navigator("/doctor-records")
                                    }).catch(err=>{
                                        setError(err.message)
                                    })
                                }
                            }}
                            style={{padding:"15px 20px 15px 20px",margin:"20px 0 20px 0", width:"50%"}}>Save</Button>
                </div>
            </div>
        </div>
    )
}
