import {doc, getFirestore, updateDoc} from "firebase/firestore";

export default async function AppointmentStatus(id, status) {
    const database = getFirestore()
    const docRef = doc(database, "appointment-request", id)
    await updateDoc(docRef, {
        status: status
    })
    return true
}
