import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function UploadProfilePic(image, id){
    return new Promise((resolve, reject)=>{
        const storage = getStorage();
        const profilePicRef = ref(storage, `profilePic/${id}.jpeg`);
        const uploadTask = uploadBytesResumable(profilePicRef, image)

        uploadTask.on('state_changed', (snapshot)=>{
            console.log("Profile pic upload progress: ", (snapshot.bytesTransferred / snapshot.totalBytes) * 100,"%")
        }, (error)=>{
            reject(error)
        }, ()=>{
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL)=>{
                resolve(downloadURL)
            })
        })
    })
}
