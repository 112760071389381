import './App.css';
import {Route, Routes} from "react-router-dom";
import { useNavigate } from 'react-router';
import Appointments from "./pages/Appointments";
import Auth from "./Auth";
import Dashboard from "./pages/Dashboard";
import {useEffect} from "react";
import DoctorRecords from "./pages/DoctorReords";
import Logo from "./raw/logo/logo.svg"
import {AddDoctor} from "./pages/AddDoctor";
import DoctorDetails from "./pages/DoctorDetails";
import Clinics from "./pages/Clinics";
import AddClinic from "./pages/AddClinic";
import AppointmentDetails from "./pages/AppointmentDetails";
import GeneratePrescription from "./pages/GeneratePrescription";
import {Email, GitHub, Instagram, LinkedIn, Phone, Twitter} from "@mui/icons-material";
import platonicc_logo from "./raw/logo/platonicc_logo.png"

function App() {
  const navigator = useNavigate();

  useEffect(()=>{
    navigator("/")
  },[])

  return (
    <div className="App">
      <div className={"bg-blue-500 text-white shadow-md flex flex-row grid grid-flow-row-dense lg:grid-flow-col-dense gap-4 py-1 px-8 items-center"}>
        <div className={"bg-white p-2 rounded-lg text-center"}>
          <img src={Logo} style={{width:"80%",margin:"auto" }}/>
        </div>
        <div className={ "hover:bg-blue-600 p-4 rounded-lg" }
             onClick={()=>{navigator("/")}}>
          Dashboard
        </div>
        <div className={ "hover:bg-blue-600 p-4 rounded-lg" }
        onClick={()=>{navigator("/appointments")}}>
          Appointments
        </div>
        <div className={ "hover:bg-blue-600 p-4 rounded-lg" }
             onClick={()=>{navigator("/doctor-records")}}>
          Doctor Records
        </div>
        <div className={ "hover:bg-blue-600 p-4 rounded-lg" }
             onClick={()=>{navigator("/clinics")}}>
          Clinics
        </div>
      </div>
      <div className={"w-full h-full"}>
        <Routes>
          <Route exact path={"/"} element={<Dashboard/>}/>
          <Route exact path={"/appointments"} element={<Appointments/>}/>
          <Route exact path={"/appointment/:id"} element={<AppointmentDetails/>}/>
          <Route exact path={"/doctor-records"} element={<DoctorRecords/>}/>
          <Route exact path={"/clinics"} element={<Clinics/>}/>
          <Route exact path={"/add-clinic"} element={<AddClinic/>}/>
          <Route exact path={"/auth"} element={<Auth/>}/>
          <Route exact path={"/add-doctor/:id/:edit"} element={<AddDoctor/>}/>
          <Route exact path={"/add-doctor"} element={<AddDoctor/>}/>
          <Route exact path={"/edit-doctor/:id"} element={<AddDoctor/>}/>
          <Route exact path={"/doctor-details/:id"} element={<DoctorDetails/>}/>
          <Route exact path={"/prescription/:bookName/:bookAge/:bookGender/:bookContact"} element={<GeneratePrescription/>}/>
          <Route exact path={"/prescription/:doctorName/:doctorContact"} element={<GeneratePrescription/>}/>
          <Route exact path={"/prescription/"} element={<GeneratePrescription/>}/>
        </Routes>
      </div>
      <div className={"bg-gray-100 p-4 mt-32"}>
        <div className={"grid md:grid-cols-4 grid-cols-1 grid-flow-cols"}>
          <div className={"text-left"}>
            <div className={"text-xl border-b-2 mr-4 flex flex-row content-center items-center p-2"}><img src={platonicc_logo} alt={"platonicc.png"} width={"36"}/> <span className={"ml-2"}>Platonicc OSS</span></div>
            <div className={"text-xs break-auto mt-2 p-2 border-b-2 mr-4"}>An open-source/freelancing software development group</div>
            <div className={"mt-2"}>
              <a href={"https://github.com/Platonicc"} target={"_blank"}><GitHub/> Platonicc</a>
            </div>
          </div>
          <div className={"text-xl text-left mt-8 md:mt-0"}>
            Developer Contact<br/>
            <div className={"text-left text-xs mt-4"}><a href={"tel:7909048102"} target={"_blank"}><Phone/> +91 (0) 7909048102</a></div>
            <div className={"text-left text-xs mt-4"}><a href={"mailto:asdevoffical@gmail.com"} target={"_blank"}><Email/> asdevoffical@gmail.com</a></div>
          </div>
          <div className={"text-xl text-left mt-8 md:mt-0"}>
            Tech Support<br/>
            <div className={"text-left text-xs mt-4"}><a href={"mailto:asdevoffical@gmail.com"} target={"_blank"}><Email/> asdevoffical@gmail.com</a></div>
          </div>
          <div className={"text-xl text-left mt-8 md:mt-0"}>
            Developer Social<br/>
            <div className={"text-left text-xs mt-4"}><a href={"https://www.github.com/amannirala13"} target={"_blank"}> <GitHub/> Github</a></div>
            <div className={"text-left text-xs mt-4"}><a href={"https://www.linkedin.com/in/amannirala13"} target={"_blank"}> <LinkedIn/> LinkedIn</a></div>
            <div className={"text-left text-xs mt-4"}><a href={"https://www.instagram.com/amannirala13"} target={"_blank"}> <Instagram/> Instagram</a></div>
            <div className={"text-left text-xs mt-4"}><a href={"https://www.twitter.com/amannirala13"} target={"_blank"}> <Twitter/> Twitter</a></div>
          </div>
        </div>
        <div className={"mt-8"}>
          <span className={"mt-8 text-xs"}>This web application has been developed by <a href={"https://github.com/Platonicc"}><u>Platonicc group</u>.</a> Any issues/queries related to the platform must be passed on the the provided email address. Use Google Chrome or Firefox browser for a better view.</span>
        </div>
      </div>
    </div>
  );
}

export default App;
