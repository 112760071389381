import {useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import {fetchAppointmentByID} from "../firebase/fetch/appointment";
import {Button, ButtonGroup} from "@mui/material";
import Switch from '@mui/material/Switch';
import AppointmentStatus from "../firebase/alter/AppointmentStatus";
import {FileCopyRounded} from "@mui/icons-material";

export default function AppointmentDetails(){
    const {id} = useParams()

    const navigator = useNavigate()

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [age, setAge] = useState(0)
    const [gender, setGender] = useState("")
    const [address, setAddress] = useState("")
    const [issue, setIssue] = useState("")
    const [status, setStatus] = useState(0)
    const [registeredOn, setRegisteredOn] = useState(null)
    const [data, setData] = useState({})

    useEffect(()=>{
        refreshData()
        return ()=>{}
    },[])

    useEffect(()=>{
        setName(data.name)
        setPhone(data.phone)
        setEmail(data.email)
        setAge(data.age)
        setGender(data.gender)
        setAddress(data.address)
        setIssue(data.issue)
        setStatus(data.status)
        if(data.registeredOn)
            setRegisteredOn(data.registeredOn.toDate())
    },[data])

    function refreshData(){
        fetchAppointmentByID(id)
            .then(res=>{
                setData(res)
            }).catch(err=>{
                alert(err)
        })
    }


    return(
        <div className={"p-4 br-white"}>
            <div className={"br-white rounded-xl shadow-2xl m-4 md:w-1/2 m-auto"}>
                <div className={"text-2xl p-4 bg-blue-500 text-white mb-4 font-bold"}>Appointment</div>
                <div className={"mt-8 text-center"}>
                    <span className={"font-bold text-md"}>Date: {(registeredOn !== null)?registeredOn.toLocaleString():"N/A"}</span><br/><br/>
                    <span className={"font-bold text-xl"}>Attended: </span>
                    <Switch
                        color={"success"}
                        checked={Boolean(status)}
                        onChange={async (e) => {
                            const value = e.target.checked
                            const status_value = value ? 1 : 0
                            setStatus(status_value)
                            await AppointmentStatus(id, status_value)
                            await alert("Updated status!")
                            refreshData()
                        }}/>
                </div>
                <div className={"mt-16 ml-8 mr-8 text-left "}>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Name:</span> <span className={"ml-4 text-xl"}>{name}</span>
                    </div>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Age:</span> <span className={"ml-4 text-xl"}>{age}</span>
                    </div>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Gender:</span> <span className={"ml-4 text-xl"}>{(gender==='male')?"Male":(gender==='female')?"Female":"Not specified"}</span>
                    </div>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Phone:</span> <span className={"ml-4 text-xl text-green-700 text-bold bg-green-50"}>{(phone)?<a href={`tel:${phone}`}>{phone}</a>:"N/A"}</span>
                    </div>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Email:</span> <span className={"ml-4 text-xl text-blue-700 text-bold bg-blue-50"}>{(email)?<a href={`mailto:${email}`}>{email}</a>:"N/A"}</span>
                    </div>
                    <div className={"mb-4"}>
                        <span className={"font-bold text-xl"}>Address:</span> <span className={"ml-4 text-xl"}>{address}</span>
                    </div>
                    <div className={"mt-10 text-center bg-green-100 rounded-xl p-4 mb-8"}>
                        <div className={"font-bold text-2xl w-1/2 text-green-600 m-auto"}>Concern</div>
                        <div className={"mt-4 text-left"}>
                            <span className={"ml-4 text-xl"}>{issue}</span>
                        </div>
                    </div>
                </div>
                <Button
                    startIcon={<FileCopyRounded/>}
                    variant={"contained"}
                    size={"large"}
                    style={{marginBottom:"24px"}}
                    onClick={_=>{
                        navigator(`/prescription/${name}/${age}/${gender}/${phone}`)
                    }}
                >Generate Prescription</Button>
            </div>
        </div>
    )
}
