import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Auth from "./Auth";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB20G1Lap0CCbdxZr3YdVCm2kneitz5meo",
    authDomain: "drhm-fc6f4.firebaseapp.com",
    projectId: "drhm-fc6f4",
    storageBucket: "drhm-fc6f4.appspot.com",
    messagingSenderId: "145387476520",
    appId: "1:145387476520:web:26d3c3c505118902fa071d",
    measurementId: "G-J190KJPTE9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

onAuthStateChanged(getAuth(), (user)=>{
    if(!user){
        ReactDOM.render(
            <HashRouter>
                <React.StrictMode>
                    <Auth/>
                </React.StrictMode>
            </HashRouter>,
            document.getElementById('root')
        );
    }else{
        ReactDOM.render(
            <HashRouter>
                <React.StrictMode>
                    <App/>
                </React.StrictMode>
            </HashRouter>,
            document.getElementById('root')
        );
    }
})
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
