import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";

/**
 * Login firebase user
 * @param email
 * @param password
 * @returns {Promise<unknown>}
 */
export function loginUser(email, password) {
    return new Promise((resolve, reject)=>{
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                resolve(user)
            })
            .catch((error) => {
                reject(error)
            });
    })
}

/**
 * Logout user
 * @returns {Promise<unknown>}
 */
export function logoutUser(){
    return new Promise((resolve, reject)=>{
        const auth = getAuth()
        signOut(auth).then(()=>{
            resolve(true)
        }).catch((error)=>{reject(error)})
    })
}
